<template>
  <div
    :class="[
      'vmp-user-registration',
      {
        'gray-theme': signUpPageType === 'subject' && isDataPage
      }
    ]"
    v-loading="loading"
    element-loading-text="加载中，请稍候"
    element-loading-background="rgba(255,255,255,.9)"
  >
    <!-- 7.5.7.2优化 数据展示 -->
    <div class="totalShow">
      <div v-if="!(signUpPageType === 'subject' && !statistics.total_num)" class="change_inline">
        共计报名用户
        <span>{{ statistics.total_num || 0 }}</span>
        位，线上报名
        <span>{{ statistics.online_num || 0 }}</span>
        位 ，审核通过
        <span>{{ statistics.audit_adopt_num || 0 }}</span>
        位
      </div>
      <div class="change_inline" v-if="signUpPageType === 'webinar'">
        ；报名用户中，观看直播人数
        <span>{{ statistics.watch_num || 0 }}</span>
        位，占比
        <span>{{ statistics.watch_percentage || 0 }}%</span>
      </div>
    </div>
    <!-- 7.5.7.2end -->
    <!-- 全部无结果 -->
    <div class="all-no-data" v-if="isDefaultShow">
      <null-page
        nullType="nullData"
        text="暂无专题数据，请去专题下的直播活动查看数据吧！"
        :height="0"
        v-if="signUpPageType === 'subject' && isDataPage"
      ></null-page>
      <null-page nullType="nullData" text="暂无数据" :height="0" v-else>
        <vh-button
          type="primary"
          class="length106"
          size="medium"
          round
          v-preventReClick
          @click.prevent.stop="addUserDialog"
        >
          快速报名
        </vh-button>
        <vh-button
          type="white-primary"
          class="length106"
          size="medium"
          round
          plain
          v-preventReClick
          @click.prevent.stop="importUserDialog"
        >
          导入
        </vh-button>
      </null-page>
    </div>
    <!-- 全部有结果 -->
    <div class="all-yes-data" v-else>
      <!-- 搜索 -->
      <div class="list--search">
        <!-- 专题下，导出数据页效果 -->
        <div class="btnGroup">
          <vh-button
            size="medium"
            type="primary"
            round
            :class="signUpPageType === 'subject' && isDataPage ? 'transparent-btn' : ''"
            @click.prevent.stop="addUserDialog"
          >
            快速报名
          </vh-button>
          <vh-button
            size="medium"
            :class="signUpPageType === 'subject' && isDataPage ? 'transparent-btn' : ''"
            plain
            round
            v-preventReClick
            @click.prevent.stop="importUserDialog"
          >
            导入
          </vh-button>
          <vh-button
            v-if="showExportUserBtn"
            size="medium"
            round
            plain
            v-preventReClick
            @click.prevent.stop="downloadHandle"
            :class="signUpPageType === 'subject' && isDataPage ? 'transparent-btn' : ''"
          >
            导出
          </vh-button>
          <vh-dropdown @command="handleCommand">
            <vh-button plain round :disabled="curCheckeds.length == 0">批量操作</vh-button>
            <vh-dropdown-menu slot="dropdown">
              <vh-dropdown-item command="1" v-if="baseInfo_child.is_audit">
                批量通过
              </vh-dropdown-item>
              <vh-dropdown-item command="2" v-if="baseInfo_child.is_audit">
                批量不通过
              </vh-dropdown-item>
              <vh-dropdown-item command="">批量删除</vh-dropdown-item>
            </vh-dropdown-menu>
          </vh-dropdown>
        </div>
        <div class="searchGroup">
          <div class="filterGroup">
            <vh-date-picker
              v-model="filterDate"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="datePicker"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              round
            ></vh-date-picker>
            <vh-select
              round
              v-model.trim="query.is_enter"
              @change="initQueryUserList"
              v-if="signUpPageType === 'webinar'"
            >
              <vh-option value="" label="是否观看"></vh-option>
              <vh-option
                v-for="item in [
                  {
                    value: 1,
                    label: '是'
                  },
                  {
                    value: 0,
                    label: '否'
                  }
                ]"
                :key="'v_' + item.value"
                :label="item.label"
                :value="item.value"
              ></vh-option>
            </vh-select>
            <vh-select round v-model.trim="query.type" @change="initQueryUserList">
              <vh-option value="" label="报名方式"></vh-option>
              <vh-option
                v-for="item in [
                  {
                    value: 1,
                    label: '线上报名'
                  },
                  {
                    value: 2,
                    label: '导入'
                  }
                ]"
                :key="'t_' + item.value"
                :label="item.label"
                :value="item.value"
              ></vh-option>
            </vh-select>
            <vh-select
              v-show="baseInfo_child.is_audit"
              round
              v-model.trim="query.audit_status"
              @change="initQueryUserList"
            >
              <vh-option value="" label="审核状态"></vh-option>
              <vh-option
                v-for="item in [
                  {
                    value: 1,
                    label: '已通过'
                  },
                  {
                    value: 2,
                    label: '未通过'
                  },
                  {
                    value: 0,
                    label: '审核中'
                  }
                ]"
                :key="'t_' + item.value"
                :label="item.label"
                :value="item.value"
              ></vh-option>
            </vh-select>
          </div>
          <vh-input
            placeholder="搜索手机号/邮箱/姓名/公司"
            v-model.trim="query.keyword"
            clearable
            round
            @clear="initQueryUserList"
            class="search-query"
            @input="checkoutList"
            v-clearEmoij
            @keyup.enter.native="initQueryUserList"
          >
            <i class="vh-icon-search vh-input__icon" slot="prefix" @click="initQueryUserList"></i>
          </vh-input>
        </div>
      </div>
      <!-- 有消息内容 -->
      <div class="list-table-panel" v-if="userDao.list.length != 0">
        <!-- 表格与分页 -->
        <vh-table
          ref="userTable"
          :data="userDao.list || []"
          @selection-change="handleTableCheckbox"
          max-height="504px"
          :header-cell-style="{
            background: '#f7f7f7',
            color: '#666',
            height: '56px'
          }"
        >
          <vh-table-column type="selection" width="60" align="left" />
          <vh-table-column
            align="left"
            v-for="(item, index) in userTableColumn"
            :key="index"
            :width="item.width"
            :label="item.label"
            :show-overflow-tooltip="!item.customTooltip"
            :fixed="item.key == 'name' ? true : false"
          >
            <template slot-scope="scope">
              <span :class="item.key" @click="doClickRow(scope.row, item)">
                <template v-if="item.key == 'audit_status'">
                  {{ getAuditText(scope.row) }}
                </template>
                <template v-else>{{ scope.row[item.key] }}</template>
              </span>
            </template>
          </vh-table-column>
          <vh-table-column
            label="操作"
            fixed="right"
            align="left"
            :width="baseInfo_child.is_audit ? '200px' : '80px'"
          >
            <template slot-scope="scope">
              <template v-if="baseInfo_child.is_audit">
                <span
                  v-if="subject_verify_live_child && signUpPageType == 'subject'"
                  class="tool"
                  @click="openLives(scope.row)"
                >
                  审核
                </span>
                <template v-else>
                  <span
                    v-if="scope.row.audit_status == 0 || scope.row.audit_status == 2"
                    class="tool"
                    @click="
                      batchAudit({
                        audit_status: 1,
                        // phone: [scope.row.phone],
                        // email: [scope.row.email]
                        selectedRows: [scope.row]
                      })
                    "
                  >
                    通过
                  </span>
                  <span
                    v-if="scope.row.audit_status == 0 || scope.row.audit_status == 1"
                    class="tool"
                    @click="
                      batchAudit({
                        audit_status: 2,
                        // phone: [scope.row.phone],
                        // email: [scope.row.email]
                        selectedRows: [scope.row]
                      })
                    "
                  >
                    不通过
                  </span>
                </template>
              </template>

              <span
                class="tool"
                @click="
                  batchDelete({
                    // phone: [scope.row.phone],
                    // email: [scope.row.email],
                    selectedRows: [scope.row]
                  })
                "
              >
                删除
              </span>
            </template>
          </vh-table-column>
        </vh-table>
        <SPagination
          :total="userDao.total || 0"
          :page-size="query.limit"
          :currentPage="query.pageNumber"
          @current-change="currentChangeHandler"
          @size-change="handleSizeChange"
        ></SPagination>
      </div>
      <!-- 无消息内容 -->
      <null-page
        class="search-no-data"
        :height="0"
        v-if="userDao && userDao.total === 0"
      ></null-page>
    </div>
    <!-- 快速报名 -->
    <add-user-form
      v-if="addUserVisible"
      :visible="addUserVisible"
      :webinarOrSubjectId="webinarOrSubjectId"
      :signUpPageType="signUpPageType"
      :isBilingual="isBilingual"
      :languageType="languageType"
      @close="cancelAddUser"
    ></add-user-form>
    <!-- 导入报名用户excel -->
    <import-dialog
      v-if="importVisible"
      :visible="importVisible"
      :webinarOrSubjectId="webinarOrSubjectId"
      :signUpPageType="signUpPageType"
      :isBilingual="isBilingual"
      :languageType="languageType"
      @close="cancelImport"
      @success="successImport"
    ></import-dialog>
    <vh-dialog
      :visible.sync="dialogLivesVisible"
      :width="baseInfo_child.is_audit ? '540px' : '440px'"
      draggable
      :part-block="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div slot="title">&nbsp;</div>
      <SubjectLives
        ref="subjectLives"
        :isBilingual="isBilingual"
        :languageType="languageType"
        :livesOps="livesOps"
        :belongUser="true"
        maxHeight="280px"
        :selectable="false"
        :showCheckbox="false"
        :showAudit="baseInfo_child.is_audit ? true : false"
        :setParamsIdByRoute="setParamsIdByRoute"
        @auditFinish="auditFinish"
      ></SubjectLives>
    </vh-dialog>

    <ExportUserDialog
      :visible.sync="exportUserVisible"
      :query="query"
      :hasDownloadPdf="hasDownloadPdf"
      :webinarId="webinarOrSubjectId"
      :sourceType="signUpPageType"
      :languageType="languageType"
    ></ExportUserDialog>
  </div>
</template>

<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import AddUserForm from './components/addUserForm.vue';
  import ImportDialog from './components/importDialog.vue';
  import SubjectLives from '../../SpecialModule/components/subjectLives.vue';
  import ExportUserDialog from '@/components/ExportUser/index.vue';
  const AuditStatus = ['审核中', '已通过', '未通过'];
  import { sessionOrLocal } from '@/utils/utils';

  export default {
    name: 'RoleList',
    inject: {
      subject_verify_live: { value: 'subject_verify_live', default: false },
      baseInfo_main: { value: 'baseInfo_main', default: false }
    },
    components: {
      NullPage,
      AddUserForm,
      ImportDialog,
      SubjectLives,
      ExportUserDialog
    },
    props: {
      // 活动ID 或者 专题ID，跟signUpPageType字段组合使用
      webinarOrSubjectId: {
        type: [Number, String],
        default: 0
      },
      // 报名表单类型：webinar--活动；subject--专题
      signUpPageType: {
        type: [Number, String],
        default: ''
      },
      // 是否双语专题
      isBilingual: {
        type: Boolean,
        default: false
      },
      // 1 中文，2：英文, 默认为 1
      languageType: {
        type: [Number, String],
        default: 1
      }
    },
    data() {
      return {
        AuditStatus,
        vm: null,
        /*--------------------列表部分参数定义--------------------*/
        loading: false,
        isDataPage: location.href.indexOf('/special/data/') != -1,
        query: {
          pos: 0,
          limit: 20,
          pageNumber: 1,
          is_enter: '',
          type: '',
          keyword: '',
          start_time: '',
          end_time: '',
          audit_status: ''
        },
        userDao: {
          total: 1,
          list: []
        },
        isHandle: false, // 是否有操作项
        baseTableColumn: [
          {
            label: '用户信息',
            key: 'name',
            width: 'auto'
          },
          {
            label: '手机号',
            key: 'phoneTxt',
            width: 150
          },
          {
            label: '邮箱',
            key: 'emailTxt',
            width: 150
          },
          {
            label: '公司',
            key: 'companyTxt',
            width: 150
          },
          {
            label: '报名方式',
            key: 'entry_source_str',
            width: 100
          },
          {
            label: '报名时间',
            key: 'created_at',
            width: 160
          },
          {
            label: '报名活动数',
            key: 'webinar_num',
            width: 100
          },
          {
            label: '是否观看',
            key: 'is_enter_str',
            width: 100
          },
          {
            label: '观看时长',
            key: 'watch_duration',
            width: 'auto'
          },
          {
            label: '渠道来源',
            key: 'refer',
            width: 'auto'
          },
          {
            label: '审核状态',
            key: 'audit_status',
            width: 100
          },
          {
            label: '审核时间',
            key: 'audit_time',
            width: 160
          }
        ],
        /*--------------------快速报名参数定义--------------------*/
        addUserVisible: false,
        /*--------------------导入用户参数定义--------------------*/
        importVisible: false,
        statistics: {},
        dialogLivesVisible: false,
        livesOps: {},
        filterDate: [],
        curCheckeds: [],

        // 导出报名用户(有pdf权限)
        exportUserVisible: false,
        hasDownloadPdf: false, // 是否有导出pdf的权限(主子账号都限制)
        parentId: 0, // 父账户id, >0则表示当前是子账号
        childPermission: {} //子账号权限,只限制子账号
      };
    },
    watch: {
      languageType(newVal, oldVal) {
        console.log('userRegistration languageType:', newVal);
        if (newVal !== oldVal) {
          this.initComp();
          this.getStatistics();
        }
      }
    },
    computed: {
      userTableColumn() {
        // 开启审核
        if (!this.baseInfo_child.is_audit) {
          this.baseTableColumn = this.baseTableColumn.filter(item => {
            return item.key !== 'audit_status' && item.key !== 'audit_time';
          });
        }

        if (this.signUpPageType === 'webinar') {
          return this.baseTableColumn.filter(item => {
            return item.key !== 'webinar_num';
          });
        } else {
          if (this.subject_verify_live_child) {
            return this.baseTableColumn.filter(item => {
              return (
                item.key !== 'is_enter_str' &&
                item.key !== 'watch_duration' &&
                item.key !== 'created_at'
              );
            });
          } else {
            return this.baseTableColumn.filter(item => {
              return (
                item.key !== 'is_enter_str' &&
                item.key !== 'watch_duration' &&
                item.key !== 'webinar_num'
              );
            });
          }
        }
      },
      isDefaultShow() {
        let res =
          this.userDao &&
          this.userDao.total === 0 &&
          this.query.keyword == '' &&
          this.query.type == '' &&
          this.query.start_time == '' &&
          this.query.end_time == '';
        if (this.baseInfo_child.is_audit) {
          res = res && this.query.audit_status === '';
        }
        if (this.signUpPageType === 'webinar') {
          return res && this.query.is_enter == '';
        } else {
          return res;
        }
      },
      subject_verify_live_child() {
        return (this.subject_verify_live && this.subject_verify_live()) || false;
      },
      baseInfo_child() {
        return (this.baseInfo_main && this.baseInfo_main()) || false;
      },
      showExportUserBtn() {
        // if (this.signUpPageType === 'subject') return true;

        // 仅限活动
        // if (this.signUpPageType === 'webinar') {
        // 父账号永远有excel导出权限
        if (this.parentId === 0) return true;
        else if (
          // 子账号有excel导出权限 或者 pdf导出权限
          this.childPermission?.permission_data_download ||
          (this.hasDownloadPdf && this.childPermission?.permission_data_download_pdf)
        ) {
          return true;
        }
        // }
        return false;
      }
    },
    methods: {
      auditFinish() {
        this.getUserRegistrationList();
        this.getStatistics();
      },
      getAuditText(item) {
        const { audit_status, audit_adopt_webinar_count } = item;
        if (audit_status != 3) {
          return AuditStatus[audit_status];
        } else {
          return `通过${audit_adopt_webinar_count}场`;
        }
      },
      handleCommand(command) {
        if (command) {
          // 审核
          // const p = this.curCheckeds.map(e => {
          //   return e.phone;
          // });
          // const e = this.curCheckeds.map(e => {
          //   return e.email;
          // });
          this.batchAudit({
            audit_status: command,
            // phone: p,
            // email: e
            selectedRows: this.curCheckeds
          });
        } else {
          // 删除
          // const p = this.curCheckeds.map(e => {
          //   return e.phone;
          // });
          // const e = this.curCheckeds.map(e => {
          //   return e.email;
          // });
          this.batchDelete({
            // phone: p,
            // email: e
            selectedRows: this.curCheckeds
          });
        }
      },
      batchAudit(info = {}) {
        const identity_params = (info.selectedRows || []).map(item => {
          return {
            phone: item.phone,
            email: item.email,
            reg_id: item.reg_id
          };
        });

        this.$fetch(
          'regBatchAudit',
          this.$params(
            this.setParamsIdByRoute({
              audit_status: info.audit_status,
              // phone: info.phone,
              // sign_up_email: info.email
              identity_params: JSON.stringify(identity_params)
            })
          )
        ).then(res => {
          this.messageInfo('操作成功');
          this.getUserRegistrationList();
          this.getStatistics();
        });
      },
      batchDelete(info = {}) {
        this.$vhConfirm('删除后，用户需要重新提交报名信息，是否删除', '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '删除',
          roundButton: true,
          cancelButtonText: '取消'
        }).then(() => {
          const identity_params = (info.selectedRows || []).map(item => {
            return {
              phone: item.phone,
              email: item.email,
              reg_id: item.reg_id
            };
          });

          this.$fetch(
            'regBatchDelete',
            this.$params(
              this.setParamsIdByRoute({
                // phone: info.phone,
                // sign_up_email: info.email
                identity_params: JSON.stringify(identity_params) //: info.selectedRows
              })
            )
          ).then(res => {
            this.messageInfo('操作成功');
            this.getUserRegistrationList();
            this.getStatistics();
          });
        });
      },
      datePicker(val) {
        if (val) {
          this.query.start_time = val[0];
          this.query.end_time = val[1];
        } else {
          this.query.start_time = '';
          this.query.end_time = '';
        }
        this.initQueryUserList();
      },
      doClickRow(row, info) {
        console.log(row, info);
        if (info.key == 'webinar_num') {
          this.openLives(row);
        }
      },
      // 页码改变按钮事件
      currentChangeHandler(current) {
        this.query.pageNumber = current;
        this.query.pos = parseInt((current - 1) * this.query.limit);
        this.getUserRegistrationList();
      },
      // 每页显示个数选择器的选项设置 改变按钮事件
      handleSizeChange(current) {
        this.query.pageNumber = 1;
        this.query.limit = current;
        this.query.pos = 0;
        this.getUserRegistrationList();
      },
      // 复选框操作
      handleTableCheckbox(val) {
        console.log(val);
        this.curCheckeds = val;
      },
      // 设置接口入参，是活动维度 还是 专题维度
      setParamsIdByRoute(params) {
        if (this.signUpPageType === 'webinar') {
          params.webinar_id = this.webinarOrSubjectId;
        } else if (this.signUpPageType === 'subject') {
          params.subject_id = this.webinarOrSubjectId;
        }
        params.language_type = this.languageType;
        return params;
      },
      checkoutList(newValue) {
        if (!newValue) {
          this.initQueryUserList();
        }
      },
      // 开启-快速报名弹窗
      addUserDialog() {
        console.log('addUserDialog   languageType:', this.languageType);
        this.addUserVisible = true;
      },
      // 关闭-快速报名弹窗
      cancelAddUser(type) {
        this.addUserVisible = false;
        if (type === 'closeAndLoading') {
          this.initQueryUserList();
          this.getStatistics();
        }
      },
      // 跳转专题详情页
      toSubjectDetail() {
        this.$router.push({
          path: `/special/details/${this.$route.params.id}`
        });
      },
      // 获取列表数据
      getUserRegistrationList(row) {
        if (row) {
          this.query.pos = row.pos;
          this.query.pageNumber = row.pageNum;
        }
        this.loading = true;
        this.$fetch('userRegistrationList', this.$params(this.setParamsIdByRoute(this.query)))
          .then(res => {
            this.loading = false;
            // 有数据但是当前页没有
            if (
              res &&
              res.code === 200 &&
              res.data &&
              res.data.list.length == 0 &&
              res.data.total != 0
            ) {
              this.getUserRegistrationList({ pos: 0, pageNum: 1 });
              return;
            }
            if (res && res.code === 200 && res.data && res.data.list) {
              res.data.list.map(item => {
                item.entry_source_str =
                  item.entry_source == 2 || item.entry_source == 3 ? '导入' : '线上报名';
                item.is_enter_str = item.is_enter == 1 ? '是' : '否';
                item.name = item.name || '--';
                // item.phone = item.phone;
                item.phoneTxt = item.phone || '--';
                // item.email = item.email;
                // item.company = item.company;
                item.emailTxt = item.email || '--';
                item.companyTxt = item.company || '--';
                item.created_at = item.created_at || '--';
                item.refer = item.refer || '--';
                item.watch_duration = item.watch_duration || '--';
                item.audit_time = item.audit_time || '--';
              });
              this.userDao = res.data;
            } else {
              this.userDao = {
                total: 0,
                list: []
              };
            }
          })
          .catch(e => {
            this.loading = false;
            console.log(e);
            this.userDao = {
              total: 0,
              list: []
            };
          });
      },
      initComp() {
        this.query = {
          pos: 0,
          limit: 20,
          pageNumber: 1,
          is_enter: '',
          type: '',
          keyword: '',
          start_time: '',
          end_time: '',
          audit_status: ''
        };
        this.initQueryUserList();
      },
      initQueryUserList() {
        this.query.pos = 0;
        this.query.pageNumber = 1;
        // this.query.limit = 20;
        // 表格切换到第一页
        this.$nextTick(() => {
          if (this.$refs.userTable && this.$refs.userTable.pageInfo) {
            this.$refs.userTable.pageInfo.pageNum = 1;
            this.$refs.userTable.pageInfo.pos = 0;
          }
          this.getUserRegistrationList();
        });
      },
      //文案提示问题
      messageInfo(message) {
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 2000,
          message: message,
          type: 'success',
          customClass: 'zdy-info-box'
        });
      },
      // 导出报名用户
      downloadHandle() {
        console.log('childPermission:', this.childPermission);
        // if (this.signUpPageType === 'webinar') {
        if (this.parentId > 0) {
          // 如果是子账号
          if (this.hasDownloadPdf && this.childPermission?.permission_data_download_pdf) {
            // 有pdf权限
            this.exportUserVisible = true;
            return;
          } else if (this.childPermission?.permission_data) {
            // 仅有excel权限
            this.execDownloadExport();
            return;
          } else {
            return;
          }
        } else {
          // 父账号
          if (this.hasDownloadPdf) {
            this.exportUserVisible = true;
            return;
          } else {
            // 仅有excel权限
            this.execDownloadExport();
            return;
          }
        }
        // } else {
        //   this.execDownloadExport();
        // }
      },
      // 执行导出逻辑
      execDownloadExport() {
        let params = {
          type: this.query.type,
          keyword: this.query.keyword,
          start_time: this.query.start_time,
          end_time: this.query.end_time,
          audit_status: this.query.audit_status
        };
        if (this.signUpPageType === 'webinar' && this.query.is_enter !== '') {
          params.is_enter = this.query.is_enter;
        }
        this.$fetch('exportForm', {
          ...this.setParamsIdByRoute({}),
          ...params
        }).then(res => {
          if (this.vm) {
            this.vm.close();
          }
          this.messageInfo(
            `导出申请成功，${
              this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
            }`
          );
          this.$EventBus.$emit('saas_vs_download_change');
        });
      },
      // 导入
      importUserDialog() {
        this.importVisible = true;
      },
      // 关闭导入用户名称
      cancelImport() {
        this.importVisible = false;
      },
      // 导入成功
      successImport() {
        this.importVisible = false;
        this.messageInfo('导入成功');
        this.initQueryUserList();
        this.getStatistics();
      },
      // 获取统计数据
      getStatistics() {
        let params = {
          language_type: this.languageType
        };
        if (this.signUpPageType === 'webinar') {
          params.webinar_id = this.webinarOrSubjectId;
        } else {
          params.subject_id = this.webinarOrSubjectId;
        }
        this.$fetch('getStatistics', params).then(res => {
          this.loading = false;
          if (res && res.code === 200) {
            this.statistics = res.data;
          }
        });
      },
      openLives(info = {}) {
        this.livesOps = {
          phone: info.phone,
          sign_up_email: info.email,
          subject_user_id: info.reg_id,
          selectedRows: [info]
        };
        this.dialogLivesVisible = true;
        this.$nextTick(() => {
          this.$refs.subjectLives.initComp();
        });
        console.log(info);
      },
      // 初始化权限
      initPermission() {
        const SAAS_VS_PES = sessionOrLocal.get('SAAS_VS_PES', 'localStorage');
        let permissions = SAAS_VS_PES ? JSON.parse(SAAS_VS_PES) : null;
        this.hasDownloadPdf = permissions ? !!permissions['download_pdf'] : false;

        let userInfo = sessionOrLocal.get('userInfo');
        if (userInfo) {
          userInfo = JSON.parse(userInfo);
          this.parentId = userInfo.parent_id;
          if (this.parentId > 0) {
            // 有子账号
            this.getChildPermission();
          }
        }
      },
      getChildPermission() {
        this.$fetch('getChildPermission')
          .then(res => {
            console.log('getChildPermission', res);
            sessionOrLocal.set('SAAS_V3_SON_PS', JSON.stringify(res.data));
            this.childPermission = res.data;
          })
          .catch(err => {
            console.error(err);
            sessionOrLocal.removeItem('SAAS_V3_SON_PS');
            this.childPermission = {};
          });
      }
    },
    mounted() {
      this.initComp();
      this.getStatistics();

      this.initPermission();
    }
  };
</script>

<style lang="less" scoped>
  /* 列表样式-------------------------------------- */
  .all-no-data {
    /* 基于外边框已经有距离： padding: 24px 24px 24px 24px; */
    padding-top: 105px;
    ::v-deep .createActive {
      padding-bottom: 30px;
    }
  }
  ::v-deep .search-no-data {
    padding-top: 128px;
    .search {
      padding-bottom: 0;
    }
  }
  ::v-deep .list--search {
    margin-bottom: 20px;
    .vh-input {
      width: 180px;
      float: right;

      .vh-input__prefix {
        cursor: pointer;
      }
    }
    .vh-select {
      width: 108px;
      .vh-input {
        width: 108px;
      }
      margin-right: 14px;
    }
  }
  .vmp-user-registration--search {
    margin-bottom: 20px;
    .vh-select {
      float: right;
      margin-right: 20px;
    }
    .vh-input {
      width: 220px;
      float: right;

      ::v-deep .vh-input__prefix {
        cursor: pointer;
      }
    }
    .search-input {
    }
  }
  .vmp-user-registration {
    ::v-deep .vh-table {
      margin-bottom: 32px;
    }
    &.gray-theme {
      .all-no-data {
        /* 基于外边框已经有距离： padding: 24px 24px 24px 24px; */
        padding-top: 137px;
      }
      .list-table-panel {
        background: #ffffff;
        padding: 24px 24px 40px 24px;
      }
      ::v-deep .search-no-data {
        padding-top: 60px;
        background: #ffffff;
        padding-bottom: 120px;
      }
    }
    .totalShow {
      height: 46px;
      line-height: 26px;
      span {
        color: #1a1a1a;
        color: #fb3a32;
        font-size: 22px;
      }
      .change_inline {
        color: #1a1a1a;
        display: inline-block;
      }
    }
  }
</style>
<style lang="less">
  .user-manage .list--search .vh-select .vh-input__inner {
    height: 36px;
    line-height: 36px;
  }
  .user-manage .list--search .search-query {
    width: 240px;
  }
  .user-manage .list--search .search-query .vh-input__inner {
    height: 36px;
    line-height: 36px;
  }
  .vmp-user-registration .all-yes-data {
    .btnGroup {
      margin-bottom: 16px;
      .vh-dropdown {
        margin-left: 12px;
        button {
          height: 36px;
          padding-top: 10px;
        }
      }
    }
    .searchGroup {
      display: flex;
      justify-content: space-between;
      .filterGroup {
        display: flex;
        align-items: center;
        .vh-date-editor--daterange.vh-input__inner {
          width: 240px;
          height: 36px;
          margin-right: 14px;
        }
        .vh-date-editor .vh-range__icon,
        .vh-date-editor .vh-range-separator {
          line-height: 30px;
        }
      }
    }
    .list-table-panel {
      min-height: auto;
      .vh-table {
        .vh-table__body .cell {
          .webinar_num,
          .tool {
            color: #3562fa;
            cursor: pointer;
          }
        }
        .vh-table__row .tool {
          margin-left: 16px;
        }
        td:last-child,
        th:last-child {
          padding-right: 24px;
          text-align: right;
        }
      }
    }
  }
  @media (min-width: 1920px) {
    .vmp-user-registration .list--search {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      .btnGroup {
        margin-bottom: 0;
      }
    }
  }
</style>
