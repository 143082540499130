<template>
  <div class="comp-add-user-form">
    <VhallDialog
      :width="subject_verify_live_child ? '798px' : '718px'"
      :visible.sync="visibleTemp"
      title="快速报名"
      class="user-add-dialog"
      v-if="visibleTemp"
      @close="cancelAddUser"
      append-to-body
    >
      <!-- 一级表单 -->
      <vh-button
        type="primary"
        round
        size="mini"
        class="add-btn"
        style="margin-bottom: 12px"
        @click.prevent.stop="addItem"
      >
        <i class="iconfont-v3 saasline-plus"></i>
        <span>添加</span>
      </vh-button>
      <vh-table
        ref="addTable"
        :data="list"
        style="width: 100%"
        :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '40px' }"
        :cell-class-name="setLastCellStyle"
        height="244"
        lazy
      >
        <vh-table-column
          type="index"
          label="序号"
          :width="70"
          class-name="sort-column"
        ></vh-table-column>
        <vh-table-column label="手机号" :width="185">
          <template slot-scope="scope">
            <div
              :class="[
                'table-item-column',
                {
                  error: scope.row.phone_error
                }
              ]"
            >
              <vh-input
                v-model.trim="scope.row.phone"
                autocomplete="off"
                placeholder="请输入手机号"
                :maxlength="15"
                show-word-limit
              ></vh-input>
            </div>
            <!-- {{scope.row.phone_error}} -->
          </template>
        </vh-table-column>
        <vh-table-column label="姓名">
          <template slot-scope="scope">
            <div
              :class="[
                'table-item-column',
                {
                  error: scope.row.name_error
                }
              ]"
            >
              <vh-input
                v-model.trim="scope.row.name"
                v-clearEmoij
                autocomplete="off"
                placeholder="请输入姓名"
                :maxlength="30"
                show-word-limit
              ></vh-input>
              <!-- <div class="table-item-btn" v-if="scope.$index !== 0 && signUpPageType != 'subject'">
                <i
                  class="iconfont-v3 saasicon_delete"
                  @click.prevent="removeItem(scope.$index)"
                ></i>
              </div> -->
            </div>
          </template>
        </vh-table-column>
        <!-- <vh-table-column label="来源" width="138" v-if="signUpPageType == 'subject'">
          <template slot-scope="scope">
            <div class="table-item-column">
              <vh-input
                v-model.trim="scope.row.source"
                v-clearEmoij
                autocomplete="off"
                placeholder="请输入来源"
                :maxlength="20"
                show-word-limit
              ></vh-input>
            </div>
          </template>
        </vh-table-column> -->
        <vh-table-column label="邮箱" :width="200">
          <template slot-scope="scope">
            <div
              :class="[
                'table-item-column',
                {
                  error: scope.row.email_error
                }
              ]"
            >
              <vh-input
                v-model.trim="scope.row.email"
                autocomplete="off"
                placeholder="请输入邮箱"
                :maxlength="60"
                show-word-limit
              ></vh-input>
              <div class="table-item-btn" v-if="scope.$index !== 0 && signUpPageType != 'subject'">
                <i
                  class="iconfont-v3 saasicon_delete"
                  @click.prevent="removeItem(scope.$index)"
                ></i>
              </div>
            </div>
          </template>
        </vh-table-column>
        <vh-table-column
          label="报名活动"
          :width="100"
          v-if="subject_verify_live_child && signUpPageType == 'subject'"
        >
          <template slot-scope="scope">
            <div class="table-item-column numLives">
              <div class="num" @click="openLives(scope.$index)">
                {{ scope.row.webinar_ids_num }}
              </div>
              <div class="table-item-btn" v-if="scope.$index !== 0 && signUpPageType == 'subject'">
                <i
                  class="iconfont-v3 saasicon_delete"
                  @click.prevent="removeItem(scope.$index)"
                ></i>
              </div>
            </div>
          </template>
        </vh-table-column>
      </vh-table>
      <p class="add-user-notice">
        注：此报名通道会导致用户信息的不完整，姓名必填，手机号和邮箱2选1作为必填即可
      </p>
      <span slot="footer" class="dialog-footer">
        <vh-button
          @click.prevent.stop="cancelAddUser"
          type="info"
          plain
          v-preventReClick
          size="medium"
          round
        >
          取消
        </vh-button>
        <vh-button
          type="primary"
          v-preventReClick
          @click.prevent.stop="saveAddUser"
          size="medium"
          round
        >
          确定
        </vh-button>
      </span>
    </VhallDialog>
    <vh-dialog
      title="设置需要报名的活动"
      :visible.sync="dialogLivesVisible"
      width="434px"
      custom-class="subjectLives"
      :part-block="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <SubjectLives
        ref="subjectLives"
        :isBilingual="isBilingual"
        :languageType="languageType"
        :needCheckAll="true"
        :isCheckallFlag="true"
        :checkedStr="checkedStr"
        maxHeight="280px"
        @getCheckedIds="getCheckedIds"
      ></SubjectLives>
      <div slot="footer" class="dialog-footer">
        <vh-button size="medium" type="info" plain round @click="dialogLivesVisible = false">
          取消
        </vh-button>
        <vh-button size="medium" type="primary" round @click="confirmData">确定</vh-button>
      </div>
    </vh-dialog>
  </div>
</template>

<script>
  import regRule from '@/utils/reg-rule';
  import SubjectLives from '../../../SpecialModule/components/subjectLives.vue';
  export default {
    name: 'CompAddUserForm',
    inject: {
      subject_verify_live: { value: 'subject_verify_live', default: false },
      getLivesTotal: { value: 'getLivesTotal', default: 0 }
    },
    components: {
      SubjectLives
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      // 活动ID 或者 专题ID，跟signUpPageType字段组合使用
      webinarOrSubjectId: {
        type: [Number, String],
        default: 0
      },
      // 报名表单类型：webinar--活动；subject--专题
      signUpPageType: {
        type: [Number, String],
        default: ''
      },
      // 是否双语专题
      isBilingual: {
        type: Boolean,
        default: false
      },
      // 1 中文，2：英文, 默认为 1
      languageType: {
        type: [Number, String],
        default: 1
      }
    },
    computed: {
      subject_verify_live_child() {
        return (this.subject_verify_live && this.subject_verify_live()) || false;
      },
      livesTotal() {
        return (this.getLivesTotal && this.getLivesTotal()) || 0;
      }
    },
    data() {
      return {
        visibleTemp: null,
        vm: null,
        list: [
          {
            name: '',
            phone: '',
            name_error: '',
            phone_error: '',
            email_error: '',
            lives_error: '',
            webinar_ids: 0,
            webinar_ids_num: 0
          }
        ],
        dialogLivesVisible: false,
        curUserIndex: 0,
        checkedIds: '',
        checkedStr: '0'
      };
    },
    methods: {
      // 设置接口入参，是活动维度 还是 专题维度
      setParamsIdByRoute(params = {}) {
        if (this.signUpPageType === 'webinar') {
          params.webinar_id = this.webinarOrSubjectId;
        } else if (this.signUpPageType === 'subject') {
          params.subject_id = this.webinarOrSubjectId;
        }
        params.language_type = this.languageType;
        return params;
      },
      setLastCellStyle({ row, column, rowIndex, columnIndex }) {
        return this.list && this.list.length <= 4 && columnIndex == 2 ? 'last-cell' : '';
      },
      // 输入限制，只能输入0-9数字
      handleInput(value) {
        if (value.phone.length == 0) {
          return;
        }

        value.phone = value.phone.replace(/[^\d]+/g, '');

        // const pattern = regRule.mobile; // 正整数的正则表达式
        // if (!pattern.test(value.phone)) {
        //   value.phone = '';
        // }
      },
      // 删除单条记录
      removeItem(index) {
        if (index !== -1) {
          this.list.splice(index, 1);
        }
      },
      // 添加单条记录
      addItem() {
        this.list.push({
          name: '',
          phone: '',
          name_error: '',
          phone_error: '',
          email_error: '',
          lives_error: '',
          webinar_ids: 0,
          webinar_ids_num: this.livesTotal
        });
        // 滚动到table最后
        this.$nextTick(() => {
          this.$refs.addTable.bodyWrapper.scrollTop = this.$refs.addTable.bodyWrapper.scrollHeight;
        });
      },
      // 校验单行
      checkItem(row, index) {
        this.checkPhone(row, index);
        this.checkName(row, index);
        this.checkEmail(row, index);
        if (this.subject_verify_live_child && this.signUpPageType === 'subject') {
          this.checkLives(row, index);
        }
      },
      // 验证手机号
      checkPhone(row, index) {
        // console.log('当前手机号', row.phone)
        if (!row.phone) {
          row.phone_error = '请输入手机号';
        } else if (!regRule['mobileForeign'].exec(row.phone)) {
          row.phone_error = '手机号格式有误';
        } else {
          // 判断当前是否存在重复数据
          // row.phone_error = this.checkRepeatPhone(row);
          row.phone_error = '';
        }
      },
      // 验证邮箱
      checkEmail(row, index) {
        if (!row.email) {
          row.email_error = '请输入邮箱';
        } else if (!regRule['email'].exec(row.email)) {
          row.email_error = '邮箱格式有误';
        } else {
          row.email_error = '';
        }
      },
      // 判断手机号是否重复
      checkRepeatPhone(row) {
        // 获取是否重复数据
        const phones = this.list.map(item => item.phone);
        let countPhones = phones.reduce(function (allPhone, phone) {
          if (phone in allPhone) {
            allPhone[phone]++;
          } else {
            allPhone[phone] = 1;
          }
          return allPhone;
        }, {});
        // console.log('当前是否具备重复数据', countPhones)
        if (countPhones[row.phone] > 1) {
          return '手机号重复';
        } else {
          return '';
        }
      },
      // 验证姓名
      checkName(row, index) {
        if (!row.name) {
          row.name_error = '请输入姓名';
        } else {
          row.name_error = '';
        }
      },
      // 验证报名活动
      checkLives(row, index) {
        if (!row.webinar_ids_num) {
          row.lives_error = '请至少选择一个活动';
        } else {
          row.lives_error = '';
        }
      },
      // 取消快速报名
      cancelAddUser() {
        this.$emit('close', 'close');
      },
      //文案提示问题
      messageInfo(message, type) {
        if (this.vm) {
          this.vm.close();
        }
        this.vm = this.$vhMessage({
          showClose: true,
          duration: 2000,
          message: message,
          type: type ? type : 'warning'
        });
      },
      // 保存 - 快速表单内容
      saveAddUser() {
        // 输入框验证
        this.list.forEach(item => {
          this.checkItem(item);
        });
        // 判断数据未填写完成的
        let nullList = this.list.filter(item => item.name && (item.phone || item.email));
        // console.log('当前空数组数据', nullList)
        if (nullList && nullList.length != this.list.length) {
          this.messageInfo('请填写后保存');
          return;
        }
        // 验证数据填写未通过的
        for (let i = 0; i < this.list.length; i++) {
          const element = this.list[i];
          if (element.name_error) {
            this.messageInfo(element.name_error);
            return;
          } else if (element.phone_error || element.email_error) {
            if (element.email_error && !element.email && !element.phone_error) {
              this.list[i].email_error = '';
            }
            if (element.phone_error && !element.phone && !element.email_error) {
              this.list[i].phone_error = '';
            }
            if (element.phone_error && element.phone) {
              this.list[i].email_error = '';
              this.messageInfo(element.phone_error);
              return;
            }
            if (element.email_error && element.email) {
              this.list[i].phone_error = '';
              this.messageInfo(element.email_error);
              return;
            }

            // if (element.phone && element.email) {
            //   this.messageInfo(element.phone_error || element.email_error);
            //   return;
            // }
          } else if (element.lives_error) {
            this.messageInfo(element.lives_error);
            return;
          }
        }

        // 数据存储
        const saveData = this.list.map(item => {
          return {
            name: item.name,
            phone: item.phone,
            email: item.email,
            source: item.source,
            webinar_ids: item.webinar_ids
          };
        });
        let params = {
          import: JSON.stringify(saveData)
        };
        // 快速报名数据保存
        this.$fetch('userRegistrationAdd', this.setParamsIdByRoute(params))
          .then(resV => {
            if (resV && resV.code == 200 && resV.data) {
              this.messageInfo('保存成功', 'success');
              this.list = [];
              this.$emit('close', 'closeAndLoading');
            } else {
              this.renderErrorInfo(resV);
            }
          })
          .catch(resV => {
            this.renderErrorInfo(resV);
          });
      },
      // 快速报名保存 - 异常处理
      renderErrorInfo(resV) {
        if (resV.code == 512825) {
          this.messageInfo(resV.msg || '保存失败');
          const repeatPhones = resV.data.list.map(item => {
            return item.phone;
          });
          // 格式化错误提示
          this.list.map(item => {
            if (repeatPhones.includes(item.phone)) {
              item.phone_error = '手机号重复';
            } else {
              item.phone_error = '';
            }
          });
        } else {
          this.messageInfo(resV.msg || '保存失败', 'error');
        }
      },
      openLives(info) {
        console.log(info);
        this.curUserIndex = info;
        this.checkedStr = this.list[this.curUserIndex].webinar_ids + '';
        this.dialogLivesVisible = true;
        this.$nextTick(() => {
          this.$refs.subjectLives.selectTarget();
        });
      },
      confirmData() {
        this.list[this.curUserIndex].webinar_ids = this.checkedIds ? this.checkedIds.join(',') : 0;
        this.list[this.curUserIndex].webinar_ids_num = this.checkedIds
          ? this.checkedIds.length
          : this.livesTotal;
        this.dialogLivesVisible = false;
      },
      getCheckedIds(info) {
        this.checkedIds = info;
      }
    },
    created() {
      console.log('created addUserForm languageType:', this.languageType);
      // 外层控制内层dialog是否开启
      this.visibleTemp = this.visible;
      this.list[0].webinar_ids_num = this.livesTotal;
    },
    mounted() {
      console.log('mounted addUserForm languageType:', this.languageType);
    }
  };
</script>
<style scoped lang="less">
  /* 快速报名样式-------------------------------------- */
  .user-add-dialog {
    // 滚动条的宽度

    ::v-deep .vh-form-item {
      margin-bottom: 24px;
    }
    .table-item-column {
      display: flex;
      align-items: center;
      justify-content: center;
      &.error {
        ::v-deep input.vh-input__inner,
        ::v-deep input.vh-input__inner:focus,
        ::v-deep input.invalid,
        ::v-deep input.invalid:focus {
          border-color: #fb3a32;
        }
      }
    }
    .table-item-btn {
      padding-left: 8px;
      i {
        color: #999999;
      }
    }
    .add-user-notice {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #999999;
      padding-top: 14px;
    }
    .numLives {
      justify-content: flex-start;
      .num {
        width: 40px;
        cursor: pointer;
        color: #3562fa;
      }
    }
  }
  .add-btn {
    i.saasline-plus {
      margin-right: 6px;
      font-size: 12px;
    }
  }
</style>
<style lang="less">
  .user-add-dialog {
    .vh-table td,
    .vh-table th {
      padding: 0;
    }
    .vh-table::before {
      height: 0;
    }
    .vh-table td,
    .vh-table th.is-leaf {
      border: none;
    }
    .vh-table .cell,
    .vh-table th > .cell {
      padding: 6px 4px !important;
      .vh-input__inner {
        height: 36px;
      }
    }
    .vh-input--limit .vh-input__inner {
      padding-right: 42px;
    }
    .vh-table td:first-child,
    .vh-table th:first-child {
      padding-left: 20px;
    }
    .vh-table .vh-table__body-wrapper {
      overflow-x: hidden;
    }
  }
  .subject-viewer .vmp-user-registration .subjectLives .vh-table {
    margin-bottom: 0;
  }
</style>
