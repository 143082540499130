<template>
  <div class="subjectLives">
    <!-- 表格 -->
    <vh-table
      ref="elTable"
      :data="dialogTableList"
      tooltip-effect="dark"
      style="width: 100%"
      :max-height="maxHeight"
      v-if="total > 0"
      :header-cell-style="headerCellStyle"
      :header-cell-class-name="selectable ? 'selectable' : 'disSelectable'"
      :row-style="rowStyle"
      @selection-change="changeDialogCheck"
      @select-all="checkAllRow"
    >
      <!-- <vh-table-column
          :reserve-selection="true"
          type="selection"
          width="55"
          align="left"
        /> -->
      <vh-table-column
        v-if="showCheckbox"
        type="selection"
        width="48"
        :selectable="
          () => {
            return selectable;
          }
        "
        align="left"
      />
      <vh-table-column
        :label="languageType === 2 ? 'Title' : '标题'"
        class-name="name"
        :show-overflow-tooltip="true"
        prop="subject"
      ></vh-table-column>
      <vh-table-column
        :label="belongUser ? '报名时间' : languageType === 2 ? 'Starting time' : '开始时间'"
        class-name="time"
        :prop="belongUser ? 'created_at' : 'start_time'"
        width="136"
      ></vh-table-column>
      <vh-table-column label="审核状态" width="80" class-name="name" v-if="showAudit">
        <template slot-scope="scope">
          {{ getAuditStr(scope.row) }}
        </template>
      </vh-table-column>
      <vh-table-column label="操作" class-name="name" v-if="showAudit">
        <template slot-scope="{ row }">
          <span
            class="tool pass"
            v-if="row.audit_status == 0 || row.audit_status == 2"
            @click="batchAudit(row, 1)"
          >
            通过
          </span>
          <span
            class="tool"
            v-if="row.audit_status == 0 || row.audit_status == 1"
            @click="batchAudit(row, 2)"
          >
            不通过
          </span>
        </template>
      </vh-table-column>
    </vh-table>
    <null-page
      text="未搜索到相关内容"
      nullType="search"
      noSearchText="暂无数据"
      v-else
      :height="60"
    ></null-page>
  </div>
</template>
<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';

  export default {
    name: 'subjectLives',
    data() {
      return {
        total: 1,
        isCheckAll: false,
        dialogTableList: [],
        dialogMulti: [],
        pageInfo: {
          pos: 0,
          limit: 200,
          pageNum: 1
        },
        totalPages: 0
      };
    },
    props: {
      // 是否是双语双流
      isBilingual: {
        type: Boolean,
        default: false
      },
      // 1 中文，2：英文, 默认为 1
      languageType: {
        type: [Number, String],
        default: 1
      },
      isCheckallFlag: {
        type: Boolean,
        default: false
      },
      showCheckbox: {
        type: Boolean,
        default: true
      },
      //是否显示审核
      showAudit: {
        type: [Number, Boolean],
        default: false
      },
      selectable: {
        type: Boolean,
        default: true
      },
      headerCellStyle: {
        type: Object,
        default: () => {
          return { background: '#f2f2f2', color: '#262626', height: '40px' };
        }
      },
      rowStyle: {
        type: Object,
        default: () => {
          return {};
        }
      },
      subject_id: {
        type: String,
        default: ''
      },
      belongUser: {
        type: Boolean,
        default: false
      },
      livesOps: {
        type: Object,
        default: () => {
          return {};
        }
      },
      needCheckAll: {
        type: Boolean,
        default: false
      },
      checkedStr: {
        type: String,
        default: '0'
      },
      maxHeight: {
        type: String,
        default: '360px'
      },
      setParamsIdByRoute: {
        type: Function,
        default: null
      }
    },
    components: {
      NullPage
    },
    watch: {
      languageType(newVal, oldVal) {
        // console.log('----------subjectLives newVal:', newVal, '---oldVal:', oldVal);
        if (newVal !== oldVal) {
          this.$nextTick(() => {
            this.initComp();
          });
        }
      }
    },
    created() {
      console.log('created subjectLives languageType:', this.languageType);
      this.initComp();
    },
    mounted() {
      console.log('mounted subjectLives languageType:', this.languageType);
    },
    methods: {
      batchAudit(info={}, status) {
        const identity_params = (this.livesOps?.selectedRows || []).map((item)=>{
          return {
            phone: item.phone,
            email: item.email,
            reg_id: item.reg_id
          }
        })

        this.$fetch(
          'regBatchAudit',
          this.$params(
            this.setParamsIdByRoute({
              audit_status: status,
              // phone: [this.livesOps.phone],
              // sign_up_email: [this.livesOps.sign_up_email],
              identity_params: JSON.stringify(identity_params),
              audit_webinar_id: info.webinar_id
            })
          )
        ).then(res => {
          this.$vhMessage({
            showClose: true,
            duration: 2000,
            message: '操作成功',
            type: 'success',
            customClass: 'zdy-info-box'
          });
          this.initComp();
          this.$emit('auditFinish');
        });
      },
      getAuditStr(item) {
        return ['审核中', '已通过', '未通过'][item.audit_status];
      },
      // 获取资料库-弹出框内容
      getDialogTableList() {
        const params = {
          subject_id: this.subject_id || this.$route.params.id || this.$route.params.str,
          ...this.pageInfo,
          ...this.livesOps
        };
        if (this.isBilingual) {
          params.language_type = this.languageType;
        }
        this.$fetch(
          this.belongUser ? 'getRegistrationFormWebinar' : 'getSubjectWebinarlist',
          this.$params(params)
        ).then(res => {
          if (res && res.code === 200) {
            // 根据中英文过滤
            // const list = res.data.list.filter(item => {
            //   return item.language_type == this.languageType;
            // });
            this.dialogTableList = res.data.list;
            if (this.isCheckAll || this.needCheckAll) {
              this.$nextTick(() => {
                this.$refs.elTable?.toggleAllSelection();
              });
            }
            this.total = res.data.total;
            this.totalPages = Math.ceil(res.data.total / this.pageInfo.limit);
            if (!this.belongUser) {
              this.$EventBus.$emit('livesTotal', this.total);
            }
          }
        });
      },
      searchHandle() {
        // this.dialogMulti = [];
        try {
          this.$refs.elTable?.clearSelection();
        } catch (e) {
          console.log(e);
        }
        this.pageInfo.pos = 0;
        this.pageInfo.pageNum = 1;
        this.getDialogTableList();
      },
      changeDialogCheck(val) {
        console.log('checked', val);
        this.dialogMulti = val.map(item => item.webinar_id || item.id);
        this.$emit('getCheckedIds', this.dialogMulti);
      },
      checkAllRow(selection) {
        console.log('全选与非全选', selection);
        // 只要数量大于0，即是够了全选
        this.isCheckAll = selection && selection.length > 0;
        if (this.isCheckAll && this.isCheckallFlag) {
          //如果全选 则传0
          this.$emit('getCheckedIds', 0);
        }
      },
      initComp() {
        console.log('subjectLives initComp languageType:', this.languageType);
        // 历史已经选择过的数据清空
        this.dialogMulti = [];
        this.dialogTableList = [];
        this.searchHandle();
      },
      saveCheckHandle() {
        console.log('this.dialogMulti', this.dialogMulti);
        if (this.dialogMulti && this.dialogMulti.length > 0) {
          this.$refs.elTable?.clearSelection();
          this.dialogMulti = [];
        }
      },
      cancelCheckHandle() {
        this.dialogMulti = [];
        try {
          this.$refs.elTable?.clearSelection();
        } catch (e) {
          console.log(e);
        }
      },
      selectAll() {
        if (this.dialogMulti && this.dialogMulti.length != this.total) {
          this.$refs.elTable?.toggleAllSelection();
        }
      },
      selectTarget() {
        this.$refs.elTable?.clearSelection();
        if (this.checkedStr != '0') {
          this.dialogTableList.map((e, index) => {
            let id = e.webinar_id || e.id;
            if (this.checkedStr.indexOf(id) != -1) {
              this.$refs.elTable?.toggleRowSelection(e);
            }
          });
        } else {
          this.selectAll();
        }
      }
    }
  };
</script>
<style lang="less" scoped>
  .word-list {
    padding-bottom: 24px;
    .word-status {
      margin-right: 12px;
    }
    .head-btn {
      margin-bottom: 16px;
    }
    .vh-input {
      width: 220px;
      .vh-input__icon {
        cursor: pointer;
      }
    }
    .search-tag {
      ::v-deep .vh-input__suffix {
        cursor: pointer;
      }
    }
  }
  .btn-center {
    margin: 24px auto 0 auto;
    text-align: right;
    .vh-button {
      &:last-child {
        margin-right: 0;
      }
    }
  }
  ::v-deep .select-option {
    float: left;
    line-height: 20px;
    margin-top: 8px;
    ::v-deepstrong {
      color: #fb3a32;
    }
  }
  .word-list {
    ::v-deep .vh-table__body .vh-table__row td:nth-child(2) .cell {
      padding-left: 10px;
    }
    ::v-deep thead tr th:nth-child(2) .cell {
      padding-left: 10px;
    }
    ::v-deep .cell .imgs {
      width: 100px;
      height: 100px;
    }
    ::v-deep .cell .advImg {
      width: 142px;
      height: 80px;
    }
    ::v-deep .vh-table {
      margin-bottom: 30px;
    }

    ::v-deep .word-status i.iconfont-v3 {
      font-size: 20px;
    }
    .text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .empty {
      text-align: center;
    }
  }
</style>
<style lang="less">
  .subjectLives {
    min-height: 200px;
    .vh-table td,
    .vh-table th {
      padding: 8px 0;
      line-height: 1;
    }
    .vh-table .cell,
    .vh-table th > .cell {
      // padding: 0 !important;
      padding-left: 0;
      max-width: 100%;
      .vh-checkbox {
        margin-left: 0;
      }
    }
    .vh-table {
      .vh-table-column--selection .cell {
        padding-right: 0;
      }
      .name {
        font-size: 14px;
        color: #262626;
      }
      td.time {
        font-size: 12px;
        color: #595959;
      }
      .disSelectable {
        .vh-checkbox {
          z-index: -1;
        }
      }
      .vh-table__body .cell {
        .tool {
          &.pass {
            margin-right: 5px;
          }
          color: #3562fa;
          cursor: pointer;
        }
      }
    }
    .vh-table td:first-child,
    .vh-table th:first-child {
      padding-left: 24px;
    }
  }
  .fieldSetBox .subjectLives,
  .entryFormBox .subjectLives {
    min-height: auto;
  }
</style>
